import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,   
  MDBCol,   
} from 'mdb-react-ui-kit';


import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 




function Photogallery() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  // const customStyles = {
  //   colMd6: {
  //     width: '50%',
  //     '@media (max-width: 768px)': {
  //       width: '100%',
  //   },
  // },
  // };

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const images = [

// nuevos 1

    {
      src: 'img/photogallery/Horizontal-01b.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-03b.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-04b.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-02b.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-06b.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-05b.jpg',
      alt: 'Krane Station',
    },

// nuevos 2


    {
      src: 'img/photogallery/Horizontal-01c.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-02c.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-04c.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-03c.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-05c.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-06c.jpg',
      alt: 'Krane Station',
    },

    // nuevos 3


    {
      src: 'img/photogallery/Horizontal-02d.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-01d.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-04d.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-03d.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-06d.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/Horizontal-05d.jpg',
      alt: 'Krane Station',
    },

      // nuevos 4


      {
        src: 'img/photogallery/Horizontal-01e.jpg',
        alt: 'Krane Station',
      },
      {
        src: 'img/photogallery/Horizontal-04e.jpg',
        alt: 'Krane Station',
      },
      {
        src: 'img/photogallery/Horizontal-05e.jpg',
        alt: 'Krane Station',
      },
      {
        src: 'img/photogallery/Horizontal-02e.jpg',
        alt: 'Krane Station',
      },

      {
        src: 'img/photogallery/Horizontal-03e.jpg',
        alt: 'Krane Station',
      },
      {
        src: 'img/photogallery/Horizontal-06e.jpg',
        alt: 'Krane Station',
      },


// viejos

    {
      src: 'img/photogallery/horizontal-01.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--45.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--43.jpg',
      alt: 'Boat on Calm Water',
    },
    {
      src: 'img/photogallery/horizontal-02.jpg',
      alt: 'Krane Station',
    },



    {
      src: 'img/photogallery/horizontal-03.jpg',
      alt: 'Krane Station',
    },
    {
      // src: 'img/photogallery/vertical-03.jpg',
      src: 'img/photogallery/img--44.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/horizontal-04.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img----65.jpg',
      alt: 'Krane Station',
    },
    

    {
      src: 'img/photogallery/img----66.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/horizontal-05.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/horizontal-06.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img----64.jpg',
      alt: 'Krane Station',
    },




    {
      src: 'img/photogallery/img--46.jpg',
      alt: 'Krane Station',
    },

    {
      src: 'img/photogallery/img--13.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--15.jpg',
      alt: 'Krane Station',
    },


    {
      src: 'img/photogallery/img--14.jpg',
      alt: 'Krane Station',
    },
    
    
    {
      src: 'img/photogallery/img--16.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--17.jpg',
      alt: 'Krane Station',
    },


    {
      src: 'img/photogallery/img--18.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--19.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--21.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--20.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--22.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--23.jpg',
      alt: 'Krane Station',
    },
    
    // {
    //   src: 'img/photogallery/img--23.jpg',
    //   alt: 'Krane Station',
    // },
    
    {
      src: 'img/photogallery/img--24.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--25.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--27.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--26.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--28.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--29.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--30.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--31.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--33.jpg',
      alt: 'Krane Station',
    },
    
    {
      src: 'img/photogallery/img--32.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--34.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--35.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--36.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--37.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--41.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--38.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--40.jpg',
      alt: 'Krane Station',
    },
    {
      src: 'img/photogallery/img--43.jpg',
      alt: 'Krane Station',
    },




    // {
    //   src: 'img/photogallery/img--44.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--45.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--41.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--42.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--43.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--44.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--45.jpg',
    //   alt: 'Krane Station',
    // },
    // {
    //   src: 'img/photogallery/img--46.jpg',
    //   alt: 'Krane Station',
    // },


    
    
  ];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };





  const handleKeyDown = (event) => {
    if (modalShow) {
      if (event.keyCode === 37) {
        // Flecha izquierda
        handleCarouselPrev();
      } else if (event.keyCode === 39) {
        // Flecha derecha
        handleCarouselNext();
      }
    }
  };

  const handleCarouselPrev = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleCarouselNext = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalShow]);





  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on initial render
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);




  return (
    <div className='Photogallery'>
           <MDBRow className='g-2'>

{/* nuevos */}

<MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(0)}>
             <img
               src='img/photogallery/Horizontal-01b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(1)}>
             <img
               src='img/photogallery/Vertical-03b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(2)}>
             <img
               src='img/photogallery/Vertical-04b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(3)}>
             <img
               src='img/photogallery/Horizontal-02b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(4)}>
             <img
               src='img/photogallery/Horizontal-06b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(5)}>
             <img
               src='img/photogallery/Vertical-05b.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* nuevos 2 */}

         <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(6)}>
             <img
               src='img/photogallery/Horizontal-01c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(7)}>
             <img
               src='img/photogallery/Vertical-02c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(8)}>
             <img
               src='img/photogallery/Vertical-04c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(9)}>
             <img
               src='img/photogallery/Horizontal-03c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(10)}>
             <img
               src='img/photogallery/Horizontal-05c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(11)}>
             <img
               src='img/photogallery/Vertical-06c.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


 {/* nuevos 3 */}

 <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(12)}>
             <img
               src='img/photogallery/Horizontal-02d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(13)}>
             <img
               src='img/photogallery/Vertical-01d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(14)}>
             <img
               src='img/photogallery/Vertical-04d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(15)}>
             <img
               src='img/photogallery/Horizontal-03d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(16)}>
             <img
               src='img/photogallery/Horizontal-06d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(17)}>
             <img
               src='img/photogallery/Vertical-05d.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* nuevos 4 */}

 <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(18)}>
             <img
               src='img/photogallery/Horizontal-01e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(19)}>
             <img
               src='img/photogallery/Vertical-04e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(20)}>
             <img
               src='img/photogallery/Vertical-05e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(21)}>
             <img
               src='img/photogallery/Horizontal-02e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(22)}>
             <img
               src='img/photogallery/Horizontal-03e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(23)}>
             <img
               src='img/photogallery/Vertical-06e.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

      {/* Viejos */}

 
         {/* Primera columna */}
        
         <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize">
           <span onClick={() => handleImageClick(24)}>
             <img
               src='img/photogallery/horizontal-01.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(25)}>
             <img
               src='img/photogallery/vertical--59.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(26)}>
             <img
               src='img/photogallery/vertical--60.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(27)}>
             <img
               src='img/photogallery/horizontal-02.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(28)}>
             <img
               src='img/photogallery/horizontal-03.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(29)}>
             <img
               src='img/photogallery/vertical-03.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>



         <MDBCol lg={4} md={6} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(30)}>
             <img
               src='img/photogallery/horizontal-04.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(31)}>
             <img
               src='img/photogallery/vertical-04.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(32)}>
             <img
               src='img/photogallery/vertical-05.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(33)}>
             <img
               src='img/photogallery/horizontal-05.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(34)}>
             <img
               src='img/photogallery/horizontal-06.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(35)}>
             <img
               src='img/photogallery/vertical-06.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>




         {/* x */}


         <MDBCol lg={4} md={6} className='img-mar custom-col-md-6' id="photoSize" >
           <span onClick={() => handleImageClick(36)}>
             <img
               src='img/photogallery/img--46.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(37)}>
             <img
               src='img/photogallery/vertical--47.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(38)}>
             <img
               src='img/photogallery/vertical--48.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(39)}>
             <img
               src='img/photogallery/img--14.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(40)}>
             <img
               src='img/photogallery/img--16.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(41)}>
             <img
               src='img/photogallery/vertical--49.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>



         {/* x */}


         <MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(42)}>
             <img
               src='img/photogallery/img--18.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(43)}>
             <img
               src='img/photogallery/vertical--50.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(44)}>
             <img
               src='img/photogallery/vertical--51.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(45)}>
             <img
               src='img/photogallery/img--20.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(46)}>
             <img
               src='img/photogallery/img--22.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(47)}>
             <img
               src='img/photogallery/vertical--52.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


{/* x */}



<MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(48)}>
             <img
               src='img/photogallery/img--24.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(49)}>
             <img
               src='img/photogallery/vertical--53.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(50)}>
             <img
               src='img/photogallery/vertical--54.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(51)}>
             <img
               src='img/photogallery/img--26.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(52)}>
             <img
               src='img/photogallery/img--28.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(53)}>
             <img
               src='img/photogallery/vertical--55.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


{/* x */}


<MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(54)}>
             <img
               src='img/photogallery/img--30.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(55)}>
             <img
               src='img/photogallery/vertical--56.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(56)}>
             <img
               src='img/photogallery/vertical--57.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(57)}>
             <img
               src='img/photogallery/img--32.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(58)}>
             <img
               src='img/photogallery/img--34.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(59)}>
             <img
               src='img/photogallery/vertical--58.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


{/* x */}



<MDBCol lg={4} md={6} className='img-mar'  id="photoSize">
           <span onClick={() => handleImageClick(60)}>
             <img
               src='img/photogallery/img--36.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(61)}>
             <img
               src='img/photogallery/vertical--63.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Segunda columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(62)}>
             <img
               src='img/photogallery/vertical--61.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(63)}>
             <img
               src='img/photogallery/img--38.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

         {/* Tercera columna */}
         <MDBCol lg={4} className='img-mar' id="photoSize">
           <span onClick={() => handleImageClick(64)}>
             <img
               src='img/photogallery/img--40.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(65)}>
             <img
               src='img/photogallery/vertical--60.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>



{/* x */}

{/* 
<MDBCol lg={4} md={6} className='img-mar'>
           <span onClick={() => handleImageClick(18)}>
             <img
               src='img/photogallery/img--44.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(19)}>
             <img
               src='img/photogallery/vertical--59.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>

       
         <MDBCol lg={4} className='img-mar'>
           <span onClick={() => handleImageClick(20)}>
             <img
               src='img/photogallery/vertical--61.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(21)}>
             <img
               src='img/photogallery/img--38.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol>


         <MDBCol lg={4} className='img-mar'>
           <span onClick={() => handleImageClick(22)}>
             <img
               src='img/photogallery/img--40.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
           <span onClick={() => handleImageClick(23)}>
             <img
               src='img/photogallery/vertical--60.jpg'
               className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
               alt='Krane Station'
               style={{ cursor: 'pointer' }}
             />
           </span>
         </MDBCol> */}



       </MDBRow>

       <Modal isOpen={modalShow} onRequestClose={handleCloseModal} id="modalContent">
        <Carousel 
        selectedItem={selectedImageIndex || 0} 
        showThumbs={false} 
        >
          
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt={image.alt}
                id='popup-img'
                // style={{
                //   maxWidth: '500px', 
                //   maxHeight: 'auto', 
                //   width: 'auto',
                //   height: 'auto',
                //   margin: '0 auto',
                // }}
              />
            </div>
          ))}
        </Carousel>
        <button className='btn-modal' onClick={handleCloseModal}

        >
          Close
          </button>
      </Modal>
    </div>
  );
}

export default Photogallery;











// import React, { useState, useEffect } from 'react';
// import {
//   MDBContainer,
//   MDBRow,   
//   MDBCol,   
// } from 'mdb-react-ui-kit';
// // import React, { useState, useEffect } from 'react';

// import Modal from 'react-modal';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; 


// function Photogallery() {
//   const [modalShow, setModalShow] = useState(false);
//   const [selectedImageIndex, setSelectedImageIndex] = useState(null);

//   useEffect(() => {
//     Modal.setAppElement('#root');
//   }, []);

//   const images = [
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
//       alt: 'Boat on Calm Water',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp',
//       alt: 'Wintry Mountain Landscape',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp',
//       alt: 'Boat on Calm Water',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
//       alt: 'Wintry Mountain Landscape',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp',
//       alt: 'Boat on Calm Water',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp',
//       alt: 'Wintry Mountain Landscape',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
//       alt: 'Boat on Calm Water',
//     },
//     {
//       src: 'https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp',
//       alt: 'Wintry Mountain Landscape',
//     },
//   ];

//   const handleImageClick = (index) => {
//     setSelectedImageIndex(index);
//     setModalShow(true);
//   };

//   const handleCloseModal = () => {
//     setModalShow(false);
//   };

//   return (
//     <div className='Photogallery'>
//       <MDBRow className='g-2'>
//          {/* Primera columna */}
//          <MDBCol lg={4} md={6} className='mb-4'>
//            <span onClick={() => handleImageClick(0)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Boat on Calm Water'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(1)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Wintry Mountain Landscape'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Segunda columna */}
//          <MDBCol lg={4} className='mb-4'>
//            <span onClick={() => handleImageClick(2)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Mountains in the Clouds'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(3)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Boat on Calm Water'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>

//          {/* Tercera columna */}
//          <MDBCol lg={4} className='mb-4'>
//            <span onClick={() => handleImageClick(4)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Waves at Sea'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//            <span onClick={() => handleImageClick(5)}>
//              <img
//                src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain3.webp'
//                className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//                alt='Yosemite National Park'
//                style={{ cursor: 'pointer' }}
//              />
//            </span>
//          </MDBCol>
//        </MDBRow>

//        <Modal isOpen={modalShow} onRequestClose={handleCloseModal} style={{ content: { maxWidth: '800px', margin: 'auto' } }}>
//         <Carousel selectedItem={selectedImageIndex || 0} showThumbs={false}>
//           {/* Agrega las imágenes nuevamente aquí, pero usando el índice para seleccionar la correcta */}
          



          
//           {images.map((image, index) => (
//             <div key={index}>
//               <img
//                 src={image.src}
//                 alt={image.alt}
//                 style={{
//                   maxWidth: '800px', // Ancho máximo de 800px
//                   maxHeight: '800px', // Alto máximo de 800px
//                   width: 'auto',
//                   height: 'auto',
//                   margin: '0 auto',
//                 }}
//               />
//             </div>
//           ))}
//         </Carousel>
//         <button className='btn-modal' onClick={handleCloseModal}
//         // style={{
//         //   marginTop: '10px',
//         //   alignSelf: 'flex-end', 
//         //       backgroundColor: 'green',
//         //       color: 'white',
//         //       border: 'none',
//         //       padding: '10px 20px',
//         //       cursor: 'pointer',
//         // }}
//         >
//           Cerrar Modal
//           </button>
//       </Modal>
//     </div>
//   );
// }

// export default Photogallery;




//  aca arranca otro!!!!!!!!!!!!!!!!!!





// import React from 'react';
// import {
//   MDBContainer,
//   MDBCol,
//   MDBRow,
// } from 'mdb-react-ui-kit';

// function Photogallery() {
//   return (
//     <div className='Photogallery'>
//       <MDBRow className='g-2'>
//         {/* Primera columna */}
//         <MDBCol lg={4} md={6} className='mb-4'>
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image' 
//             alt='Boat on Calm Water'
//           />
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//             alt='Wintry Mountain Landscape'
//           />
//         </MDBCol>

//         {/* Segunda columna */}
//         <MDBCol lg={4} className='mb-4'>
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//             alt='Mountains in the Clouds'
//           />
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//             alt='Boat on Calm Water'
//           />
//         </MDBCol>

//         {/* Tercera columna */}
//         <MDBCol lg={4} className='mb-4'>
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//             alt='Waves at Sea'
//           />
//           <img
//             src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain3.webp'
//             className='w-100 shadow-1-strong rounded mb-2 photogallery-image'
//             alt='Yosemite National Park'
//           />
//         </MDBCol>
//       </MDBRow>
//     </div>
//   );
// }

// export default Photogallery;
