import React from 'react';
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet'; 

export default function Map({ coordinates }) { // Recibimos las coordenadas como prop
    const customIcon = new Icon({
        iconUrl: "/img/Map-icon.png",
        iconSize: [70, 70],
        className: "custom-icon" 
    }); 

    return (
        <MapContainer 
            center={coordinates} 
            zoom={14} 
            className="map-container" // Usamos una clase CSS personalizada
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={coordinates} icon={customIcon} />
        </MapContainer>
    );
}