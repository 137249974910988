import React from "react";
import { Link } from 'react-router-dom'; 

function Collage() {
  return (
    <div className="Collage" id="Collage">
      <div className="Collage-home"
        // style={{
        //   width: '400px',
        //   height: '300px',
        //   border: '1px solid #ccc',
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   borderRadius: '10px',
        //   marginLeft: 'auto',
        //   marginRight: 'auto',
        //   backgroundColor: 'white',
        // }}
      >
        <h1 className='PhotoGallery-title' style={{ marginBottom: '30px' }}>Photo Gallery</h1>
        <Link to='./photogallery'> 
          <button className="rounded-button2">
            See more pictures
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Collage;

