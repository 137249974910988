import React from 'react';
import './App.css';
import { Link } from 'react-router-dom'; 
import { Fade } from "react-awesome-reveal";

// Componente para mostrar la imagen con el título y el enlace
function ImagenConTitulo(props) {
  const estiloDeFondo = {
    backgroundColor: props.backgroundColor || 'white'
  };

  return (
    <div className="img-con-titulo" style={estiloDeFondo}>
      <Link to={props.linkUrl} className='link-stores'>
        <img src={props.imagenUrl} alt={props.titulo} />
        <h2 className="titulo-stores" style={estiloDeFondo}>
          {props.titulo}
        </h2>
      </Link>
    </div>
  );
}

// Componente principal que muestra las diferentes tiendas
function Stores() {
  const ourServices = [
    {
      imagenUrl: "img/stores.png",
      titulo: "Ormiston Town Centre",
      backgroundColor: "#23c4f4",
      linkUrl: '/location/1'
    },
    {
      imagenUrl: "img/stores-2.png",
      titulo: "Sylvia Park",
      backgroundColor: "#e93291",
      linkUrl: '/location/2'
    },
    {
      imagenUrl: "img/stores-3.png",
      titulo: "COMING SOON!!",
      backgroundColor: "#23c4f4",
      linkUrl: '/location/3'
    }
  ];

  return (
    <section className="our-Stores" id="Stores">
      <p className='transparent'>l</p>
      <Fade triggerOnce cascade delay={300}>
        <div className='Our-Store'>
          <h1 className='Our-Stores-'>OUR STORES</h1>
        </div>
        <div className="img-container">
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
              backgroundColor={item.backgroundColor}
              linkUrl={item.linkUrl} 
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default Stores;