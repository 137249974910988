import React from 'react';
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';


function Footer() {
  return (
    <section>
      <div className='footer'>
        <ul className='list-unstyled d-flex justify-content-center flex-wrap links-container text-center' id="listFooter">
          <li className="link-item">
            <a href='/' style={{ textDecoration: 'none' }} className='linkFooter'>
              HOME
            </a>
          </li>

          <li className="link-item">
            <a href='/#Services' style={{ textDecoration: 'none' }} className='linkFooter'>
              SERVICES
            </a>
          </li>

          <li className="link-item">
            <a href='/#Stores' style={{ textDecoration: 'none' }} className='linkFooter'>
              OUR STORES
            </a>
          </li>

          <li className="link-item">
            <a href='/#Collage' style={{ textDecoration: 'none' }} className='linkFooter'>
              COLLAGE
            </a>
          </li>

          <li className="link-item">
            <a href='/Contact' style={{ textDecoration: 'none' }} className='linkFooter'>
              CONTACT
            </a>
          </li>
        </ul>
      </div>

      <div className='text-center p-3' style={{ backgroundColor: '#262a34', color: 'white' }}>
   
          Website crafted by <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration: 'none', fontWeight: "bold" }}> Natali Larroza </a>| &copy; {new Date().getFullYear()} Krane Station

      </div>

    </section>
  );
}

export default Footer;
