import React, { useState } from 'react';
import { Modal, Carousel } from 'react-bootstrap';

const GalleryButton = ({ images, modalTitle }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <div>
      <button onClick={() => handleOpenModal(0)} className="gallery-button">Gallery</button>
      
      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={selectedImageIndex} onSelect={(index) => setSelectedImageIndex(index)}>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={image.src}
                  alt={`Slide ${index}`}
                  style={{ maxHeight: '700px', objectFit: 'contain' }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button className="close" onClick={handleCloseModal}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GalleryButton;