import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

function Header() {


  const location = useLocation();

  useEffect(() => {
    // Comprueba si la URL contiene un hash y desplázate a esa sección
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="header-background">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto custom-font">
            <Nav.Link className='nav3' id='link-header' href="/">HOME</Nav.Link>
            <Nav.Link className='nav3' id='link-header' href="/#Services">SERVICES</Nav.Link>
            <Nav.Link className='nav3' id='link-header' href="/#Stores">OUR STORES</Nav.Link>
            <Nav.Link className='nav3' id='link-header' href="/#Collage">COLLAGE</Nav.Link>
            <Nav.Link className='nav3' id='link-header' href="/Contact">CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
  
//   return (
//     <div className="header-background"> 

//         <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="mx-auto custom-font">    
//             <Nav.Link className='nav3' id='link-header' href="/">HOME</Nav.Link>
//             <Nav.Link className='nav3' id='link-header' to="Services"  href="/#Services">SERVICES</Nav.Link>
//             <Nav.Link className='nav3' id='link-header' href="/#Stores">OUR STORES</Nav.Link>
//               <Nav.Link className='nav3' id='link-header' href="/#Collage">COLLAGE</Nav.Link>
//               <Nav.Link className='nav3' id='link-header' href="/Contact">CONTACT</Nav.Link>
//             </Nav>
//         </Navbar.Collapse>
//         </Navbar>
//     </div>
//   );
// }

// export default Header;



